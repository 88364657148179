// ajax 配合JQ3 引入設置
$(document).ready(function () {
  $.ajax({
    url: "ajax/_header.html",
    method: "GET",
    dataType: "html"
  }).done(
    function (data) {
      $("#header").html(data);
      toggleMenu();
      goToAnchor();
    }
  );
  $("#footer").load("ajax/_footer.html");
});

function goToAnchor() {
  $('.js-goToAnchor').click(function (e) {
    // e.preventDefault();
    var target = $(this).attr('href');
    var trigger02 = document.querySelector(".l-header-menu-content");
    var target02 = document.querySelector(".l-header-menu-btn-bar");
    trigger02.classList.remove("js-menuOpened");
    target02.classList.remove("js-menuOpened");
    document.documentElement.classList.remove("js-menuOpened");
    if (target !== "#") {
      var targetPos = $(this.hash).offset().top;
      $('html,body').animate({
        scrollTop: targetPos - 50
      }, 1000);
    }
  });
  if (window.location.hash) {
    // direct browser to top right away
    scroll(0, 0);
    // takes care of some browsers issue
    setTimeout(function () {
      scroll(0, 0);
    }, 1);
    // smooth scroll to the anchor id
    $('html,body').animate({
      scrollTop: $(window.location.hash).offset().top + 'px'
    }, 1000, 'swing');
  }
}

function btnFloatShow() {
  // console.log('TTT');
  var height = $(".p-index-kv").height() / 4;
  if ($(window).scrollTop() > height) {
    $(".c-btn-float").addClass('js-show');
  } else {
    $(".c-btn-float").removeClass('js-show');
  }
}

function toggleMenu() {
  var trigger = document.querySelector(".l-header-menu-btn");
  var menuBarEl = document.querySelector(".l-header-menu-btn-bar");
  var menuContentEl = document.querySelector(".l-header-menu-content");
  trigger.addEventListener("click", function () {
    // console.log("clicked");
    menuBarEl.classList.toggle("js-menuOpened");
    menuContentEl.classList.toggle("js-menuOpened");
    document.documentElement.classList.toggle("js-menuOpened");
  });

}

function autoFixHeight() {
  // console.log("ddd");
  var el = document.querySelectorAll(".p-products-list-title");
  var thisHeight = -1;
  var maxHeight = -1;
  var breakpoint = 768;
  if (el !== null) {
    //為了ie不支援nodelist的forEach修正
    Array.prototype.slice.call(el).forEach(function (item) {
      item.style.height = ""; //清空之前的style
      thisHeight = item.clientHeight; //取得已經減過高度的
      maxHeight = maxHeight > thisHeight ? maxHeight : thisHeight;
    });
    if (document.body.clientWidth > breakpoint) {
      Array.prototype.slice.call(el).forEach(function (item) {
        item.style.height = `${maxHeight}px`;
      });
    }
  }
}

function slickSwitcher() {
  var el = document.querySelector(".v-slick-news");
  if (el !== null) {
    $(el).slick({
      // arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }]
    });
  }
  var el02 = document.querySelector(".v-slick-product");
  if (el02 !== null) {
    var breakpoint = 992;
    $(el02).slick({
      // arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '0px',
      centerMode: true,
      // fade: true,
      cssEase: 'linear',
      responsive: [{
        breakpoint: breakpoint,
        centerMode: true,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }]
    });
    slickProductAdj(breakpoint, el02);
    window.addEventListener("resize", function () {
      slickProductAdj(breakpoint, el02);
    })
  }
}
function slickProductAdj(mediaQuery, element) {
  var el = element;
  var length = el.querySelectorAll(".slick-slide").length;
  // console.log(el);
  if (length <= 3 && window.innerWidth >= mediaQuery) {
    el.querySelector(".slick-track").classList.add("d-flex");
    el.querySelector(".slick-slide:first-child").classList.add("col-auto", "p-0", "order-1");
    el.querySelector(".slick-slide:nth-child(2)").classList.add("col-auto", "p-0", "order-2");
    el.querySelector(".slick-slide:nth-child(3)").classList.add("col-auto", "p-0", "order-0");
  } else {
    el.querySelector(".slick-track").classList.remove("d-flex");
    el.querySelector(".slick-slide:first-child").classList.remove("col-auto", "p-0", "order-1");
    el.querySelector(".slick-slide:nth-child(2)").classList.remove("col-auto", "p-0", "order-2");
    el.querySelector(".slick-slide:nth-child(3)").classList.remove("col-auto", "p-0", "order-0");
  }
}

function gsapSwitcher() {
  gsap.to(".p-index-news", {
    scrollTrigger: {
      id: "cat01",
      trigger: ".p-index-news",
      // markers: true,
      // 第一個數值是trigger觸及位置，第二個數值是scroller觸及位置
      start: "0% 75%",
      end: "100% 25%",
      // 是否根據捲動程度決定動畫完成程度，
      scrub: true,
    },
    backgroundPosition: "50% 80%",
  });
  gsap.to(".p-index-about", {
    scrollTrigger: {
      id: "cat02",
      trigger: ".p-index-about",
      // markers: true,
      // 第一個數值是trigger觸及位置，第二個數值是scroller觸及位置
      start: "0% 75%",
      end: "100% 25%",
      // 是否根據捲動程度決定動畫完成程度，
      scrub: true,
    },
    backgroundPosition: "50% 50%",
  });
}
function fadeSlick(){
  var el03 = $(".v-slick-product .slick-arrow");
  // console.log(el03);
  var trigger = $('.v-slick-product .slick-slide[data-slick-index="0"]');
  el03.click(
    function(){
      // console.log('click');
      // console.log(trigger.hasClass("slick-current"));
      if(trigger.hasClass("slick-current")){
        $(".v-slick-product .slick-cloned").addClass("js-active");
      }
      else{
        $(".v-slick-product .slick-cloned").removeClass("js-active");
      }
    }
  )
}
//呼叫function-網頁載入完成後
$(document).ready(function () {
  // toolsListener();
  autoFixHeight();
  slickSwitcher();
  gsapSwitcher();
  fadeSlick();
});
//呼叫function-視窗大小變更
$(window).resize(function () {
  autoFixHeight();
});
//呼叫function-捲動
$(window).scroll(function () {
  btnFloatShow();
});